.tableContent {
    position: relative;
    padding: 16px;
    margin-bottom: 16px;
    background: white;
    box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
      0px 1.25px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
  .filtersContainer {
    width: 100%;
    padding: 20px 20px 20px 20px;
    background: white;
    margin-bottom: 16px;
    box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
      0px 1.25px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
  
  .spaced {
    margin: 16px 0;
  }

  .searchContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow: auto;
    background: var(--color-white);
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow-wrapper);
    padding: 16px;
}

.uploadContent {
  float: right;
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  /* height: 100%; */
  /* overflow: auto; */
  background: var(--color-white);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-wrapper);
  padding: 16px;
}

.ant-input-group-wrapper, .ant-select, .ant-picker
{
  width: 100% !important;
}
