//spacing

$base-pacing: 3px;

$spacing-s: $base-pacing;
$spacing-m: 2 * $base-pacing;
$spacing-l: 3 * $base-pacing;
$spacing-xl: 4 * $base-pacing;
$spacing-xxl: 10 * $base-pacing;

//colors
$green-0: #64d59f;
$red-0: #d72d2d;
$gray-0: #f8f8f8;
$gray-1: #a9a9a9;
$gray-2: #6f7482;
$blue-0: #0048d9;
$blue-1: #2b27d5;
$blue-2: #072543; 
$black-3: #47474d;
$black-4: #161618;
$black-5: #323232;

@mixin border-floating {
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    border-radius: 2px;
}
